<template>
<div>
    <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
        <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <div class="d-flex align-items-center flex-wrap mr-1">
                <div class="d-flex align-items-baseline flex-wrap mr-5">
                    <h5 class="text-dark font-weight-bold my-1 mr-5">Report</h5>
                    <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 font-size-sm">
                        <li class="breadcrumb-item">
                           
                        </li>
                    </ul>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <div class="card-toolbar card-actions">
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column-fluid">
        <div class="container">
            <div class="card card-custom">
                <div class="card-body">
                   <div class="table-responsive">
					</div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>

import { defineComponent, ref, watch, reactive, onMounted, inject, computed } from 'vue'
import { useStore  } from 'vuex'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import {FilterMatchMode} from 'primevue/api'
import InputText from 'primevue/inputtext'
import Tag from 'primevue/tag';


import { useConfirm } from "primevue/useconfirm"
import { useToast } from "primevue/usetoast";
export default defineComponent({
    components: {  },
    setup() {
        const constant = inject('Constant')
		const http = inject('Http')
        const store = useStore()
        const confirm = useConfirm();
        const toast = useToast();

        onMounted(() => {
        })
        return {
        }
    },
})
</script>
